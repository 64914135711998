import { trackEvent } from "@hopper-b2b/api";
import { useScript } from "@hopper-b2b/utilities";
import { getLang } from "@hopper-b2b/i18n";
import { useCallback, useEffect, useState } from "react";
import {
  AuthEvents,
  AuthMachineEventType,
  useAuthState,
} from "../../../../machine";
import {
  AuthTrackingEvents,
  AuthType,
  ChoseSignupEvent,
} from "../../../../types/tracking";

interface GoogleLoginResponse {
  credential: string; // idToken field in /create/session request
}

export const GoogleLogin = ({
  googleIdentityClientId,
}: {
  googleIdentityClientId: string;
}) => {
  const locale = getLang();

  const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);

  const [, send] = useAuthState<AuthEvents, unknown>();

  const scriptElement = useScript({
    src: `https://accounts.google.com/gsi/client?hl=${locale}`,
    onLoad: () => {
      setScriptLoaded(true);
    },
  });

  const handleCredentialResponse = useCallback(
    (response: GoogleLoginResponse) => {
      send({
        type: AuthMachineEventType.GOOGLE_SIGN_IN,
        idToken: response.credential,
      });
    },
    [send]
  );

  const onClickHandler = useCallback(() => {
    trackEvent({
      eventName: AuthTrackingEvents.ChoseSignup,
      properties: {
        auth_type: AuthType.Google,
      },
    } as ChoseSignupEvent);
  }, []);

  useEffect(() => {
    if (
      !scriptLoaded ||
      !googleIdentityClientId ||
      !window ||
      !window.google ||
      !window.google.accounts
    ) {
      return;
    }

    const buttonDiv = document.getElementById("buttonDiv");

    window.google.accounts.id.initialize({
      client_id: googleIdentityClientId,
      callback: handleCredentialResponse,
      locale: getLang(),
    });
    window.google.accounts.id.renderButton(
      buttonDiv,
      {
        theme: "outline",
        size: "large",
        longtitle: true,
        width: buttonDiv.offsetWidth,
        click_listener: onClickHandler,
        locale: getLang(),
      } // customization attributes
    );

    // TODO: When updating sign up flow, move this higher up in the DOM to not be affected by conditional rendering
    // window.google.accounts.id.prompt(); // also display the One Tap dialog
  }, [
    scriptLoaded,
    googleIdentityClientId,
    handleCredentialResponse,
    onClickHandler,
  ]);

  return (
    googleIdentityClientId && (
      <>
        {scriptElement}
        <div id="buttonDiv" />
      </>
    )
  );
};

import { isHopperBrand } from "./brand";

const HOPPER_APP_WEB_VIEW_USER_AGENT = "hopperapp";
const HOPPER_APP_WEB_MOBILE_APP_VERSION_COOKIE_KEY = "mobile_app_version";
const HOPPER_APP_WEB_MOBILE_APP_BUILD_NUMBER_COOKIE_KEY =
  "mobile_app_build_number";
const HOPPER_APP_WEB_MOBILE_APP_DEVICE_ID_COOKIE_KEY = "mobile_app_device_id";
const HOPPER_APP_WEB_MOBILE_APP_OS_COOKIE_KEY = "mobile_app_os";
const ANDROID_PLATFORM = "android";
const IOS_PLATFORM = "ios";

/**
 * @returns {boolean} true if the user agent is the Hopper App web view and the website is Hopper,
 *  meaning that the page is loaded in a web view within the iOS or Android Hopper App.
 */
export const isHopperAppWebView = (): boolean =>
  isHopperBrand() &&
  typeof window !== "undefined" &&
  navigator.userAgent.toLowerCase().includes(HOPPER_APP_WEB_VIEW_USER_AGENT);

export const getMobileAppVersion = (): string | undefined =>
  readCookie(HOPPER_APP_WEB_MOBILE_APP_VERSION_COOKIE_KEY);
export const getMobileAppBuildNumber = (): string | undefined =>
  readCookie(HOPPER_APP_WEB_MOBILE_APP_BUILD_NUMBER_COOKIE_KEY);
export const getMobileAppDeviceId = (): string | undefined =>
  readCookie(HOPPER_APP_WEB_MOBILE_APP_DEVICE_ID_COOKIE_KEY);
export const getMobileAppOs = (): string | undefined =>
  readCookie(HOPPER_APP_WEB_MOBILE_APP_OS_COOKIE_KEY);
export const getMobileAppPlatform = (): string | undefined => {
  switch (getMobileAppOs()?.toLowerCase()) {
    case ANDROID_PLATFORM:
      return "m1";
    case IOS_PLATFORM:
      return "c1";
    // Invalid OS, return web platform as default
    default:
      return "w1";
  }
};

const readCookie = (key: string) => {
  let cookieValue;
  try {
    cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith(key))
      ?.split("=")[1];
  } catch (e) {
    cookieValue = "";
  }

  return cookieValue;
};

interface MessageHandler {
  postMessage(message: object): void;
}

declare global {
  interface Window {
    /**
     * This is the Android Javascript interface that the website can use to communicate with the native
     * Android Hopper mobile app.
     */
    androidJavascriptInterface: {
      openFunnelOverWebView: (funnel: string) => void;
      openFunnelFromHomeScreen: (funnel: string) => void;
      closeWebView: () => void;
      purchaseComplete: () => void;
      login: (source: string, onCompleteJavascript: string) => void;
      provideAuthTokens: (
        accessToken: string,
        refreshToken: string,
        accessTokenExpirationUnixTimestamp: number
      ) => void;
    };

    webkit: {
      messageHandlers: {
        [key: string]: MessageHandler;
      };
    };
  }
}

const isAndroid = () => {
  return typeof window.androidJavascriptInterface !== "undefined";
};
const isIOS = () => {
  return typeof window.webkit !== "undefined" && window.webkit.messageHandlers;
};

// This type correlates to the app Funnel contract.  It's a large contract, and it's unclear which Funnels will be useful, so leaving this as a placeholder.
export type AppFunnel = object;

/**
 * Open a native funnel from the web app. The funnel is opened on top of the current WebView and
 * will not close it
 */
export const openFunnelOverWebView = (funnel: AppFunnel) => {
  if (isAndroid()) {
    window.androidJavascriptInterface.openFunnelOverWebView(
      JSON.stringify(funnel)
    );
  } else if (isIOS()) {
    window.webkit.messageHandlers.funnel.postMessage(funnel);
  }
};

/**
 * Open a native funnel from the web app. The funnel is opened from the HomeScreen, closing the
 * current WebView screen.
 */
export const openFunnelFromHomeScreen = (funnel: AppFunnel) => {
  if (isAndroid()) {
    window.androidJavascriptInterface.openFunnelFromHomeScreen(
      JSON.stringify(funnel)
    );
  } else if (isIOS()) {
    window.webkit.messageHandlers.funnelFromHome.postMessage(funnel);
  }
};

/**
 * Close the WebView screen completely, even if there's other web pages in the history.
 */
export const closeWebView = () => {
  if (isAndroid()) {
    window.androidJavascriptInterface.closeWebView();
  } else if (isIOS()) {
    window.webkit.messageHandlers.closeWebView.postMessage({});
  }
};

/**
 * Notify the app that a purchase has been completed. This will refresh the required caches.
 */
export const purchaseComplete = () => {
  if (isAndroid()) {
    window.androidJavascriptInterface.purchaseComplete();
  } else if (isIOS()) {
    window.webkit.messageHandlers.purchaseComplete.postMessage({});
  }
};

/**
 * Launch the native login flow from the web app. Upon completion, the native app will update
 * the authentication tokens and execute the [onCompleteJavascript] javascript code.
 */
export const login = (source: string, onComplete: string) => {
  if (isAndroid()) {
    window.androidJavascriptInterface.login(source, onComplete);
  } else if (isIOS()) {
    window.webkit.messageHandlers.purchaseComplete.postMessage({
      source: source,
      onCompleteJavascript: onComplete,
    });
  }
};

/**
 * Update the native access and refresh tokens.  This must be called every time the web app updates the refresh token.
 */
export const provideAuthTokens = (
  accessToken: string,
  refreshToken: string,
  accessTokenExpirationUnixTimestamp: number
) => {
  if (isAndroid()) {
    window.androidJavascriptInterface.provideAuthTokens(
      accessToken,
      refreshToken,
      accessTokenExpirationUnixTimestamp
    );
  } else if (isIOS()) {
    window.webkit.messageHandlers.provideAuthTokens.postMessage({
      accessToken: accessToken,
      refreshToken: refreshToken,
      accessTokenExpirationUnixTimestamp: accessTokenExpirationUnixTimestamp,
    });
  }
};
